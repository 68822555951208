import LocalizedStrings from 'react-localization';

let LocaleStrings = new LocalizedStrings({
  en: {
    // thomson
    main_title: 'Kipplo',
    mainlogin_title: 'Log in',
    mainlogin_user_isdeleted: 'Unverified or Deleted User.',
    // mainlogin_subtitle: "Welcome back! Please enter your details to log in.",
    mainlogin_subtitle: 'Welcome back! Please enter your details.',
    mainlogin_signingoogle: 'Sign in with Google',
    mainlogin_signinlinkedin: 'Sign in with Linkedin',

    mainlogin_donthaveacc: "Don't have an account?",
    mainlogin_error_unverified: 'Unverified or Deleted User',
    mainsignup: 'Sign up',
    mainsignup_subtitle:
      'Sell more and close more with the most accurate B2B company and contact data.',
    mainsignup_business_email: 'Business Email',
    mainsignup_email_placeholder: 'Enter your email',
    mainsignup_create_password: 'Create a password',
    mainsignup_termsandcondition1: 'I Agree To The',
    mainsignup_firstname_label: 'First Name',
    mainsignup_firstname_placeholder: 'Enter First Name',
    mainsignup_lastname_label: 'Last Name',
    mainsignup_lastname_placeholder: 'Enter Last Name',
    mainsignup_businessemail_label: 'Business Email',
    mainsignup_businessemail_placeholder: 'Enter Business Email',
    mainsignup_termsandcondition2: 'Terms and Conditions',
    mainsignup_signupgoogle: 'Sign up with Google',
    mainsignup_signuplinkedin: 'Sign up with Linkedin',
    signup: 'Sign up',
    mainsignup_alreadyhavingacc: 'Already have an account?',
    mainsignup_password_validate: 'Must be atleast 8 characters',
    mainsignup_page1_crtaccbtn: 'Create account',
    mainsignup_page2_title: 'Tell us a little more...',
    mainsignup_page2_subtitle: 'Reach all your ideal customers from one place',
    mainsignup_page2_crtaccbtn: 'Create account',
    mainsignup2_updateacc: 'Update Account',
    mainsignup2_success_message: 'Successfully Updated',
    mainsignup_skip: 'Skip',
    mainsignup_passwordlength_validation:
      'Password length must be atleast 8 characters',
    mainsignup_tc_validation: 'Please accept the terms and conditions',
    mainsignup_signupgoogle: 'Sign up with Google',
    mainsignup_signuplinkedin: 'Sign up with Linkedin',

    // PREPARING LOADER
    preparing_dashboard: 'Preparing Dashboard',
    preparing_mycontacts: 'Preparing MyContacts',
    fetching_records: 'Fetching Records',
    preparing_billing: 'Preparing Billing',
    preparing_members: 'Preparing Members',
    preparing_myaccounts: 'Preparing My Accounts',
    preparing_subscription_plan: 'Preparing Subscription Plan',
    preparing_subscription_plan_settings: 'Preparing Subscription Plan',
    preparing_revealed_contacts: 'Preparing Revealed Contacts',
    preparing_usage_platform: 'Preparing Usage',

    // Freeze account Text
    account_freezed: 'Your Acccount is Freezed',

    // Units
    pcs: 'pcs',
    nos: 'nos',

    // Payment methods
    card: 'Card',
    cash: 'Cash',
    upi: 'UPI',
    netbanking: 'Net Banking',

    // Address type
    home: 'Home',
    office: 'Office',
    other: 'Other',

    // Negotiation status
    negotiating: 'Negotiating',
    accepted: 'Accepted',
    rejected: 'Rejected',

    categories: 'Categories',
    subcategories: 'Sub-categories',

    account_temporarily_unavailable: 'Account temporarily unavailable',
    temporarily_unavailable: 'Temporarily unavailable',
    sku: 'sku',
    skip: 'skip',
    accept: 'Accept',
    next: 'Next',
    previous: 'Previous',
    dollar: '$',
    buy: 'Buy',
    reject: 'Reject',
    cancel: 'Cancel',
    cancel_upload: 'Cancel upload',
    back: 'Back',
    star: 'Star',
    copied: 'Copied',
    not_applicable_in_short: 'N/A',
    cancelled: 'Cancelled',
    expired: 'Expaired',
    repayment_btn: 'Pay now',
    pay: 'Pay',
    resend: 'Resend',
    not_a_valid_phone_number: 'Not a valid phone number',
    invalid_quantity: 'Not a valid quantity',
    invalid_price: 'Invalid price given',
    report: 'Report',
    already_reported: 'Already reported',
    common_fail_message: 'Something went wrong',
    common_goback: 'Go Back',
    common_error_fillallrequiredfields: 'Please fill all required fields',
    invalid_length: 'Invalid',
    invalid: 'Invalid',
    invalid_number: 'Invalid Number',
    ok: 'ok',
    visit_again: 'Visit Again',
    feature_under_construction: 'The feature is under construction',
    invalid_email: 'Invalid email',
    invalid_date_range: 'Invalid date range',
    email: 'Email',
    email_phone: 'Email/Phone',
    password: 'Password',
    signin: 'Sign in',
    forgot_password: 'Forgot  password?',
    forgot_password_subtitle: "No worries, we'll send you reset instructions.",
    forgot_password_success_message:
      'Password Reset Link Sent to Register Email.',
    verify_your_email: 'Verify your email',
    verify_email_desc_text:
      'We sent a verification link to example@email.com. Please check the email to verify.',
    reset_password: 'Reset Password',
    backto_login: 'Back to login',
    create_a_new_account: 'Create a new account',
    signup_as_vendor: 'Signup as Vendor',
    select_the_type_of_business: 'Select the type of business you own',
    or_use_social: 'OR USE SOCIAL',
    connect_with_apple: 'Connect with Apple',
    connect_with_facebook: 'Connect with Facebook',
    connect_with_google: 'Connect with Google',
    connect_with_twitter: 'Connect with Twitter',
    company: 'Company',
    home_business: 'Home Business',
    personal_items: 'Personal Items',
    consumer: 'Consumer',
    login: 'Login',
    signout: 'Sign Out',
    login_error_other_than_vendor: 'Other Than Vendor Not Allowed to log in',
    login_form_validation_email_required: 'Please enter your email address',
    login_form_validation_password_required: 'Please enter your password',
    login_user_not_registered: 'Your account is not registered with us',
    login_user_session_expired: 'Your account access session is expired',
    // invalid_email_password:
    //   "Currently Unable to Login,Please accept invitation or supply valid credentials or admin removed you from the platform",
    // invalid_email_phone_password:
    //   "Currently Unable to Login, Please accept invitation or supply valid credentials or admin removed you from the platform",
    invalid_email_phone_password:
      'You have entered an invalid email or password',
    // unverified_user_error: "Unverified user, Please verify yourself!",
    unverified_user_error:
      'Your email is not verified. Please verify your email address and try logging in again.',
    removed_by_admin_error:
      'You have been removed from the platform by the admin',
    disabled_by_admin_error: 'Your account is temporarily disabled',
    invitationaccept_by_admin_error:
      'Please Accept your invitation and  Sign in',
    business_email_error: 'Please use a valid business email to register',
    invalid_user: 'Invalid User!',
    button_logout: 'Logout',
    button_yes: 'Yes',
    button_no: 'No',
    logout_want_to_logout: 'Do you really want to Logout?',
    select: 'SELECT',
    accept_cash_on_delivery: 'Accept cash on delivery',
    product_managemnet_istaxable_text: 'Is this product taxable ?',
    product_tax_text: 'Product tax(in %)',
    enter_product_tax_tex: 'Enter product tax(in %)',
    item_location: 'Item location',
    enter_item_location: 'Enter item location',
    select_return_time: 'Select return time',
    selling_for_charity: 'Selling for charity',
    make_a_donation: 'Make a Donation',
    edit: 'Edit',
    add: 'Add',
    ban: 'Ban',
    delete: 'Delete',
    cancel_bid: 'Cancel Bid',
    no_data_found: 'No Data found',
    geocoder_failed_due_to: 'Geocoder failed due to',
    digit_one: '1',
    digit_two: '2',
    drop_or_select: 'Drop Or Select',
    name: 'Name',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    rather_not_say: 'Rather not say',
    verify: 'Verify',
    public: 'Public',
    private: 'Private',
    percent_sign: '%',
    discount_using: 'discount using',
    might_apply: 'might apply',
    shipping: 'Shipping',
    off: 'OFF',
    connect_social_media_text: 'Connect with social media',
    or: 'Or',
    select_file: 'Click to upload image',
    button_crop_save: 'Crop & Save',
    button_upload_new_image: 'Upload New Image',
    login_sub_haeder_text: 'Enter your email/phone no. & password to login',
    create_a_account: 'Create a account',
    login_success: 'You are successfully logged in',
    login_error_emailexist:
      'This email already exist , please try with another',
    required: 'Required',
    anonymous: 'Anonymous',
    verify_email_button: 'Verify Email',
    enter_email_want_to_change:
      'Please enter the email address you want to change!',
    english: 'English',
    arabic: 'Arabic',
    no_found: 'Not found !',
    sociallogin_error_emailnotexist:
      "The account doesn't exist, Please sign up!!",

    // Review and rating common component
    rating_and: 'Rating And',
    review_and_rating_text1: '1.Product rating',
    review_and_rating_text2: '2.How accurate was the product description?',
    review_and_rating_text3:
      "3.How satisfied were you with the selle's Communication?",
    review_and_rating_text4: '4.How quickly did the seller ship the item?',

    report_confirm_review_title: 'Report review',
    report_confirm_review_message:
      'Are you sure you want to report about this review?',

    out_of_five: 'out of 5',

    // Sign Up/In/Register
    // signup: "Signup",
    signup_sub_header_text: "Let's get started by creating a new account",
    password_reset_link_text:
      'Please enter your registered email and we will send you a password reset link',

    password_reset_link_alert_text: 'Password reset link sent successfully',
    continue: 'Continue',
    business_name: 'Business Name',
    nick_name: 'Nick Name',
    date_of_birth: 'Date of birth',

    account_exist_alert_text: 'Account already exist, trying to login',

    // reset password
    reset_password_title: 'Reset Password',
    reset_password_length_error: 'Password length should be greater than 8',
    reset_password_dont_match: "Both password doesn't match",
    reset_password_success: 'Your password has been reset successfully.',
    reset_password_reset_here: 'Reset Your Password Here',
    reset_password_code: 'Code',
    reset_password_btn_reset: 'Reset',
    reset_password_success: 'Password reset Successfully',
    reset_password_field_confirm_password: 'Confirm Password',
    reset_password_code_expired:
      'The Code for this link has expired,Please try with latest',

    // Dailies and Weeks
    this_week: 'This Week',
    today: 'Today',
    last_week: 'Last Week',
    weeks_back: 'weeks back',
    after: 'After',
    weeks: 'weeks',
    next_week: 'Next Week',
    yesterday: 'Yesterday',

    // Months Name
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',

    // HEADBAR
    scheduledemo: 'Schedule a demo',
    upgrade: 'Upgrade',
    viewplan: 'View Plans',

    // COMMON
    something_went_wrong: 'Something went wrong!',
    added_successfully: 'Added Successfully',
    updated_successfully: 'Updated Successfully',
    api_success_status: 'Request has succeeded',

    // PAYMENT RESPONSE
    pay_fail_title1:
      'Sorry..... Your subscription process is failed, Try again',
    pay_success_title1:
      'Thank you for your subscription, now you can continue to access',
    pay_btn_gotoplan: 'Go To Dashboard',

    // ACCEPT INVITE
    acceptinvite_alreadyactiveuser: 'Already an active and verified user',
    acceptinvite_thomson: 'Kipplo Invite',
    acceptinvite_success:
      'Invite accepted successfull, please login to continue',
    acceptinvite_descriptiontext1:
      'Here is the invitation please accept invitation to login',
    acceptinvite_btn: 'Accept Invite',
    acceptinvite_appusernotfound: 'Appuser  not found',

    // VERIFY EMAIL
    verify_email_title1: 'Verification link has been sent to',
    verify_email_title2: 'Please check the inbox or spam folder and verify.',
    verify_email_title3: 'Did not receive any email?',
    verify_email_resend: 'Resend',
    verify_email_resend_success: 'Email sent successfully',
    verify_email_resend_verified_success: 'Successfully Verified',

    // EMAIL VERIFICATION
    email_verification_title1: 'Click on the button to verify your email',
    email_verification_title2: 'Your email -',
    email_verification_verifybtn: 'Verify Email',
    email_verification_verified_successfully:
      'Email verified successfully, please login',

    // DASHBOARD MAIN
    dashboard_main_subscriptionplan: 'Subscription Plan',
    dashboard_main_starter: 'Starter',
    dashboard_main_permonth: 'per month',
    dashboard_main_peryear: 'per year',
    dashboard_main_nextrenewal: 'Next renewal on:',
    dashboard_main_autorenew: 'Auto Renew on:',
    dashboard_main_subscription_expired: 'Subscription Expired',
    dashboard_main_noplansadded: 'No plans Added',
    dashboard_main_creaditsovers: 'Phone Number Credits Over',
    dashboard_main_contactscredit: 'Phone Number Credits Left',
    dashboard_main_contactscredit_trials: 'Phone Number Credits',
    dashboard_main_collectsupto: 'Collects up to',
    dashboard_main_searchcontacts: 'search contacts',
    dashboard_main_subscribenow_btn: 'Subscribe Now',
    dashboard_main_unbox_text: "Unbox The Kipplo's Extension Now",
    dashboard_main_download_ext_btn: 'Download Extension',
    dashboard_main_collect_contacts: 'Collects up to 200 search contacts.',
    dashboard_main_subheader_teammembers: 'Team Members',
    dashboard_main_subheader_usedseats: 'Used Seats',
    dashboard_main_subheader_totalseats: 'Total Seats',
    dashboard_main_subheader_usedtotalkey: 'Used Keys / Total Keys',
    dashboard_main_subheader_apikey: 'Api Keys',
    dashboard_main_subheader_revealedcontacts: 'Revealed Contacts',
    dashboard_main_subheader_buttonlast7: 'Last 7 days',
    dashboard_main_subheader_buttonlast30: 'Last 30 days',
    dashboard_main_subheader_buttonlast90: 'Last 90 days',
    dashboard_main_head_btn: 'Add Addon',
    dashboard_main_head_subscriptioncancelled: 'Subscription will end on:',
    dashboard_main_head_upto_25_email: 'Email Credits Left',
    dashboard_main_head_unlimited_emails: 'Unlimited Email Credits',
    dashboard_main_head_business_credits: 'Work Email Credits Left',
    dashboard_main_head_personal_credits: 'Secondary Email Credits Left',

    /*MY CONTACTS*/
    mycontacts: 'My Contacts',
    mycontacts_headerbtn: 'Export Excel',
    mycontacts_th1: 'Name',
    mycontacts_th2: 'Social Media',
    mycontacts_th3: 'Secondary Email',
    mycontacts_th4: 'Work Email',
    mycontacts_th5: 'Phone Number',
    mycontacts_linkedinprofile: 'Linkedin Profile',
    mycontacts_downvoted_success: 'Downvoted Successfully',
    mycontacts_upvoted_success: 'Upvoted Successfully',
    mycontacts_reveal: 'Show',
    partially_verified: 'Partially Verified',
    free: 'FREE',
    partially_rate: '70% average delivery rate.',
    partially_text:
      'Provided for free. This email may be valid but we cannot guarantee the accuracy because the receiving server does not allow verfications.',
    fully_verified: 'Verified',
    fully_rate: '97% average delivery rate.',
    fully_text:
      "Safe to use. This email has been confirmed by the receiving server or sourced from reputable sources, such as the company's official website.",
    noemail: 'No email found',
    nophone: 'No phone number found',
    mycontacts_upgrade: 'Upgrade to Export',

    /*Contact Credits Addons*/

    addons_loaderdesc: 'Preparing Addons',
    addons_heading: 'Phone Number Addons',
    addons_description:
      'Here is the List of phone pumber addons, buy any as per requirement',
    // addons_noaddonstobeadded:
    //   "Addons Can only be added once you have exhausted your Phone Number ",
    addons_noaddonstobeadded: "Free Plan Doesn't have addons",
    addons_payment_paymentfailed: 'Payment Failed',
    addons_payment_paymentsuccess: 'Payment completed Successfully',
    addons_payment_via: 'Payment Via',
    addons_payment_selectedaddon: 'Selected Addon:',
    addons_payment_price: 'Price:',
    addons_payment_amountpayable: 'Amount Payable:',
    addons_label_cardno: 'Card Number',
    addons_label_expirydate: 'Expiry Date',
    addons_label_cvc: 'Cvc',

    // Upgrade Plan
    upgradeplan_header: 'Simple, Transparent Pricing',
    upgradeplan_desc: 'Subscribe to an account plan that fits your workflow.',
    upgradeplan_desc_tab_button1: 'Monthly Billing',
    upgradeplan_desc_tab_button2: 'Annual Billing',
    upgradeplan_item_updated_success: 'Plan Updated Successfully',
    upgradeplan_item_btn_subscribenow: 'Subscribe now',
    upgradeplan_item_btn_upgrade: 'Upgrade',
    upgradeplan_item_btn_contactsales: 'Contact Sales',
    upgradeplan_item_btn_downgrade: 'Downgrade',
    upgradeplan_item_btn_initialplan_price: 'Free',
    upgradeplan_item_permonth: 'per month',
    upgradeplan_item_peryear: 'per year',
    upgradeplan_item_btn_currentplan: 'Current Plan',
    upgradeplan_item_btn_addon: 'Phone Number Addon',
    upgradeplan_item_features: 'Features',
    upgradeplan_downgrade_success:
      'Your plan successfully downgraded, it will be reflect after this billing period',
    upgradeplan_upgraded_success: 'Plan updated successfully',
    upgradeplan_upgraded_plansuccess: 'Your plan successfully upgraded',

    //SCHEDULE DEMO
    schedule_demo_header: 'Schedule A Demo',
    schedule_demo_description:
      'Please fill up the following field to schedule a demo',
    schedule_demo_btn: 'Schedule Demo',
    schedule_demo_success_message: 'Email sent to admin successfully',

    // Settings
    settings: 'Settings',
    settings_backtodashboard: 'Back to Dashboard',
    setting_settingsSideBarMenus1: 'My Account',
    setting_settingsSideBarMenus2: 'Password',
    setting_settingsSideBarMenus3: 'Manage Seats',
    setting_settingsSideBarMenus4: 'Billing',
    setting_settingsSideBarMenu5: 'Plans',
    setting_settingsSideBarMenus6: 'Usage',
    setting_settingsSideBarMenus7: 'API',
    setting_settingsSideBarMenus8: 'Privacy',

    // setting myaccounts
    settings_myaacounts_header: 'My Account',
    settings_myaacounts_subheader: 'Update your account details here.',
    settings_myaacounts_basicdetails: 'Basic Details',
    settings_myaacounts_displaytext: 'This will be displayed on your profile.',
    settings_myaacounts_businessdetails: 'Business Details',
    settings_myaacounts_businessdetails_subtitle:
      'A quick snapshot of your company',
    settings_myaacounts_taxbillingheader: 'Tax & Billing Address',
    settings_myaacounts_taxbilling_subtitle:
      'Please fill up your tax and billing address',
    settings_myaacounts_yourphoto: 'Your Photo',
    settings_myaacounts_btncancel: 'Cancel',
    settings_myaacounts_btnupdate: 'Update',
    settings_myaacounts_deletebtn: 'Delete',
    settings_myaacounts_field_name: 'Name',
    settings_myaacounts_field_businessemail: 'Work Email',
    settings_myaacounts_field_label_company: 'Company Name',
    settings_myaacounts_field_placeholder_company: 'Enter your company name',
    settings_myaacounts_field_phone: 'Phone Number',
    settings_myaacounts_field_country: 'Country',
    settings_myaacounts_field_label_salespeoplecount: 'Sales People Count',
    settings_myaacounts_field_placeholder_salespeoplecount:
      'Select Sales People Count',
    settings_myaacounts_field_label_leadcountrequired:
      'Lead Count Required Per Month',
    settings_myaacounts_field_placeholder_leadcountrequired:
      'Select required lead count',
    settings_myaacounts_field_label_department: 'Department',
    settings_myaacounts_field_placeholder_department: 'Select the department',
    settings_myaacounts_field_label_taxid: 'Tax id',
    settings_myaacounts_field_placeholder_taxid: 'Enter your tax id',
    settings_myaacounts_field_label_billingaddress: 'Billing Address',
    settings_myaacounts_field_placeholder_billingaddress: 'Billing Address',
    settings_myaacounts_img_success: 'Image Updated Successfully',
    settings_myaacounts_img_error: 'Please add an image!',
    settings_myaacounts_update_email: 'Update Email',
    settings_myaacounts_email_update_form: 'Update Email',
    settings_myaacounts_email_update_form_subtitle:
      '(Please verify your email once updated)',
    settings_myaacounts_email_update_form_similarmail:
      'You are already using this email!',
    settings_myaacounts_email_update_form_updatefail:
      'Something Went Wrong! Cannot Update Email.',
    settings_myaacounts_email_update_form_emailexist:
      'Email Already Exist In Our System! Please try with another',
    settings_myaacounts_change_emailbtn: 'Change Email',
    settings_myaacounts_verify_and_update_btn: 'Verify Code & Update',
    settings_myaacounts_succes_code_sent:
      'Verification Code Sent to Email! Please Enter the Code and Verify',
    settings_myaacounts_verificationcode_mismatched:
      'Verification Code Mismatched',
    settings_myaacounts_email_verified_n_updatedsuccess:
      'Email verified and update successfully',

    // setting password
    settings_password: 'Password',
    settings_passwordsubtitle: 'Change your password from here',
    settings_passwordchange: 'Change Password',
    settings_passwordchange_subtitle:
      'Please enter your current password to change your password.',
    settings_password_lablecurrentpw: 'Current Password',
    settings_password_lablenewpw: 'New Password',
    settings_password_lableminortext:
      'Your new password must be atleast 8 characters',
    settings_password_lableconfirmnewpw: 'Confirm new Password',
    settings_password_error_above8letter:
      'Please enter password of 8 or more letter',
    settings_password_update_success: 'Password Updated Successfully',
    settings_password_error_didntmatch: 'Password didnot match',

    // setting manageseats
    setting_manageseats_starter: 'Starter',
    setting_manageseats_amount: '/month',
    setting_manageseats_packagedetails:
      'Includes up to 5 users, 500 Contact Credits, 100  Credits and access to all features.',
    setting_manageseats_teammembers: 'Team Members',
    setting_manageseats_btn_addmember: 'Add member',
    setting_manageseats_table_th1: 'Name',
    setting_manageseats_table_th2: 'Role',
    setting_manageseats_table_th3: 'Date',
    setting_manageseats_table_th4: 'Credit Used',
    setting_manageseats_table_th5: 'Status',
    setting_manageseats_items_invitesuccess: 'Invite Sent Successfully',
    setting_manageseats_items_delete_title: 'Delete Member',
    setting_manageseats_items_delete_desc:
      'Are you sure you want to delete this user?',
    setting_manageseats_items_enable_title: 'Enable Member',
    setting_manageseats_items_enable_desc:
      'Are you sure you want to enable this user?',
    setting_manageseats_items_disable_title: 'Disable Member',
    setting_manageseats_items_disable_desc:
      'Are you sure you want to disable this user?',
    setting_manageseats_items_deletedsuccess: 'Successfully Deleted',
    setting_manageseats_items_enabledsuccess: 'Successfully Enabled User',
    setting_manageseats_items_disablesuccess: 'Successfully Disabled User',
    setting_manageseats_items_enabled: 'Enabled',
    setting_manageseats_items_invitation_sent: 'Invitation Sent',
    setting_manageseats_items_resendivitebtn: 'Resend Invite',
    setting_manageseats_memberaddedit_head: 'Invite Team Member',
    setting_manageseats_memberaddedit_subhead:
      'Enter email of the member and set a role',
    setting_manageseats_memberaddedit_field1_label: 'First Name',
    setting_manageseats_memberaddedit_field1_placeholder:
      'Enter member firstname',
    setting_manageseats_memberaddedit_field4_label: 'Last Name',
    setting_manageseats_memberaddedit_field4_placeholder:
      'Enter member lastname',
    setting_manageseats_memberaddedit_field2_label: 'Business Email',
    setting_manageseats_memberaddedit_field2_placeholder: 'Enter member email',
    setting_manageseats_memberaddedit_field3_label: 'Role',
    setting_manageseats_memberaddedit_field3_placeholder: 'Select Role',
    setting_manageseats_memberaddedit_btn_sendinvite: 'Send Invite',
    setting_manageseats_memberaddedit_addfail: 'Failed to add Member',
    setting_manageseats_memberaddedit_addsuccess: 'Member Added Successfully',
    setting_manageseats_memberaddedit_emailexists:
      'Email exist, please try again with another!',
    setting_manageseats_seats_available: 'Seats available',

    // setting billing
    setting_billing_heading: 'Billing',
    setting_billing_subheading: 'Manage your billling and payment details.',
    setting_billing_header_btn: 'Cancel Subscription',
    setting_billing_box1_header: 'Basic plan',
    setting_billing_box1_subheader: 'Next renewal on:',
    setting_billing_box1_subscriptionend: 'Subscription will end on:',
    setting_billing_box1_upgradeplan: 'Upgrade plan',
    setting_billing_box1_permonth: 'per month',
    setting_billing_invoice: 'Invoice',
    setting_billing_th1: 'Name',
    setting_billing_th2: 'Billing Period',
    setting_billing_th3: 'Billing Date',
    setting_billing_th4: 'Plan',
    setting_billing_th5: 'Amount',
    setting_billing_subscribe_toseebilling: 'Subscribe to see billings',

    // setting plan
    setting_plan_heading: 'Plans',
    setting_plan_subheading: 'Pick an account plan that fits your workflow.',

    // setting usage

    setting_usage_heading: 'Usage',
    setting_usage_subheading: 'View all the usage by team members and API',
    setting_usage_tab_button1: 'Platform',
    setting_usage_tab_button2: 'API',
    setting_usage_th1: 'Name',
    setting_usage_th2: 'Revealed Contacts',
    setting_usage_th3: 'Secondary Emails Revealed',
    setting_usage_th4: 'Work Emails Revealed',
    setting_usage_th5: 'Phone Numbers Revealed',
    setting_usage_th3_: 'API Contacts',
    setting_usage_th4_: 'Downloads',
    setting_usage_view_contact: 'View Contacts',
    setting_usage_reavealedcontact_modal_header: 'Revealed Contacts',
    setting_usage_reavealedcontact_modal_subheader: 'Revealed contacts by',
    setting_usage__api_th1: 'Name',
    setting_usage__api_th2: 'Api Key Name',
    setting_usage__api_th3: 'Number of Calls',
    setting_usage__api_th4: 'Error',
    setting_usage__api_th5: 'Secondary Emails Revealed',
    setting_usage__api_th6: 'Work Emails Revealed',
    setting_usage__api_th7: 'Phone Numbers Revealed',

    // SETTINGS API
    settings_api_header: 'API',
    settings_api_subheader: 'Manage and create new API',
    settings_api_createapibtn: 'Create API',
    settings_api_authorizebtn: 'Authorize',
    settings_crtapi_modal_subh: 'Please enter a key name and purpose',
    settings_crtapi_modal_label_keyname: 'Key Name',
    settings_crtapi_modal_placeholder_keyname: 'e.g. POST',
    settings_crtapi_modal_label_apipurpose: 'API Purpose',
    settings_crtapi_modal_placeholder_apipurpose: 'e.g. to get contacts',
    settings_crtapi_modal_createbtn: 'Create',
    settings_api_th1: 'API Key Name',
    settings_api_th2: 'Purpose',
    settings_api_tryout: 'Try out',
    settings_api_tryout_api: 'Endpoint URL',
    settings_api_enterapikeyandlinkedinurl:
      'Please enter API Key, Linkedin Url and \nCompany Name(for Business Emails)',
    settings_api_linkedinfield_label: 'Linkedin Url',
    settings_api_companyfield_label: 'Company Name',
    settings_api_fetchpersonalemailsbtn: 'Fetch Secondary Emails',
    settings_api_fetchbusinessemailsbtn: 'Fetch Work Emails',
    settings_api_directdialsbtn: 'Fetch Phone Numbers',
    settings_api_directdialscredits_over: 'Phone Numbers Credits Over',
    settings_api_updateapi: 'Update API',
    settings_api_updatepurpose: 'Please update purpose',
    settings_api_updatebtn: 'Update',
    settings_api_updatesuccess: 'Updated successfully',
    settings_api_createdsuccess: 'API created successfully',
    settings_api_delete_title: 'Delete API',
    settings_api_delete_desc: 'Are you sure you want to delete this API?',
    settings_api_delete_success: 'Api Deleted Successfully',
    settings_api_deletebtn: 'Delete',
    settings_api_editbtn: 'Edit',
    settings_api_apicallurl:
      '/apikipplo/index.php?linkedinurl={linkedinurl}&apikey={apikey}&type={type}',
    settings_api_documentation_header: 'API DOCUMENTATION',
    settings_api_documentation_intro: 'Introduction',
    settings_api_documentation_intro_desc1:
      "Welcome to Kipplo's API! You can use this API to access  all our API endpoints. To get details of a person using linkedin, fetching its email address and contact numer information.To Look up to information related to domain name.",
    settings_api_documentation_intro_desc2:
      ' The API is organized around REST. All request should be made over SSL. All request and responses bodies,including errors , are encoded in JSON.',
    settings_api_documentation_http_request: 'HTTP REQUEST',
    settings_api_documentation_http_method_get: 'GET',
    settings_api_documentation_parameters: 'PARAMETERS (For Post Man and Curl)',
    settings_api_documentation_parameters_desc1:
      '1. Call the above url with GET method and append the following mentioned below.',
    settings_api_documentation_parameters_desc2:
      '2. Linkedin Url - Append the linkedin url of the user you want to get information in the above url in place of {linkedinurl}.',
    settings_api_documentation_parameters_desc3:
      '3. Api Key - Add the created api from the admin panel in the above url in place of {apikey}.',
    settings_api_documentation_parameters_desc4:
      '4. Type - Add the type personalemails/businessemails/directdials(phonenumber) you want to fetch in the above url in place of {type}',
    settings_api_documentation_parameters_foradminpanel: 'For Admin Panel',
    settings_api_documentation_foradminpanel_desc1:
      '1. Add the created api which you want to use in Key Name field.',
    settings_api_documentation_foradminpanel_desc2:
      '2. Add the Linkedin url of the user you want to get information in the Linkedin Url field.',
    settings_api_documentation_foradminpanel_desc3:
      '3. Click on any button below which you wish to fetch from the API',

    //  SETTINGS PRIVACY
    setting_privacy_heading: 'Privacy',
    setting_privacy_subheading: 'Our Privacy, Terms & Conditions',
    setting_privacy_btn_view: 'View',
    setting_privacy_tab1: 'Privacy Policy',
    setting_privacy_tab2: 'Terms & Conditions',
    setting_privacy_tab3: 'Chrome Privacy',

    // SETTINGS PRIVACY -CHROME
    setting_privacy_chrome_header: 'Chrome Privacy',
    // SETTINGS PRIVACY -TERMS
    setting_privacy_terms_header: 'Terms & Conditions',
    // SETTINGS PRIVACY -PRIVACY
    setting_privacy_privacy_header: 'Privacy Policy',
    saveupto: 'Save upto 20%',
    flag: {
      email: {
        title: 'Report Invalid Email',
        subtitle: 'Report this email as invalid and provide feedback.',
        subtitle2: 'Your input ensures accurate information. Thank you!',
        invalid: 'Invalid Email',
        invalidPlaceholder: 'What is the right Email?',
        others: 'Others',
        othersPlaceholder: 'What else?',
        submit: 'Submit',
      },
      phone: {
        title: 'Report Invalid Phone Number',
        subtitle: 'Report this phone number as invalid and provide feedback.',
        subtitle2: 'Your input ensures accurate information. Thank you!',
        outofservice: 'Out of Service',
        invalid: 'Invalid Number',
        invalidPlaceholder: 'What is the right Phone number?',
        others: 'Others',
        submit: 'Submit',
      },
      cancel: 'Cancel',
    },
    dial_buy_credits: 'Buy Phone Number Credits',
    businessEmails: 'Work Email',
    personalEmails: 'Secondary Email',
    phoneNumbers: 'Phone Number',
  },
});

// module.exports = LocaleStrings;
export default LocaleStrings;
